import merge from 'lodash/merge';
import { deepmerge } from '@mui/utils';
import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    }
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 1.5
    },
    h2: {
      fontSize: 36,
      fontWeight: 600,
      lineHeight: 1.5
    },
    h3: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1.5
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.5
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 1.5
    },
    h6: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5
    },
    body1: {
      fontSize: 15,
      fontFamily: 'Roboto, Inter',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.80
    },
    body2: {
      fontSize: 15,
      fontFamily: 'Inter, Roboto',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.80
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.57
    },
    caption: {
      fontWeight: 500
    },
    overline: {
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 1,
      lineHeight: 1.66
    },
    button: {
      fontWeight: 500,
      textTransform: 'none'
    }
  }
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#f3f4f7',
        paper: '#ffffff'
      },
      error: {
        contrastText: '#ffffff',
        main: '#FB9300'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#0076ec'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c',
        green1: '#1e3e3d',
        green2: '#3eb7b7'

      },
      warning: {
        contrastText: '#ffffff',
        main: '#FB9300'
      },
      info: {
        contrastText: '#ffffff',
        main: '#0288d1'
      }
    },
    shadows: lightShadows
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#171c24',
        paper: '#222b36'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#688eff'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#FB9300'
      },
      info: {
        contrastText: '#ffffff',
        main: '#0288d1'
      }
    },
    shadows: darkShadows
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#EBA83A'
      }
    },
    shadows: darkShadows
  }
};

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createMuiTheme(deepmerge(merge({}, baseOptions, themeOptions, {
    ...(config.roundedCorners && {
      shape: {
        borderRadius: 16
      }
    })
  }, {
    direction: config.direction
  })));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};



